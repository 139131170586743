<template>
  <v-dialog
    :value="showYouTubePlayerDialog"
    fullscreen
    persistent
    class="pa-0 ma-0"
  >
    <v-card class="pa-0 ma-0">
      <v-toolbar dark color="#673AB7" class="elevation-0 pa-0 ma-0">
        <v-spacer />
        <v-btn icon @click="$emit(`close`)">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pa-0 ma-0 d-flex justify-center align-center">
        <iframe
          :width="width"
          :height="height"
          :src="`https://www.youtube.com/embed/${youTubeVideoId}`"
          title=""
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from "@mdi/js";

export default {
  name: "YouTubePlayerDialog",
  props: {
    showYouTubePlayerDialog: Boolean,
    youTubeVideoId: String,
  },
  data() {
    return {
      mdiClose,
      width: window.innerWidth,
      height: window.innerHeight - 65,
    };
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight - 65;
    },
  },
  beforeMount() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
