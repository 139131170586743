var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      staticClass: "pa-0 ma-0",
      attrs: {
        value: _vm.showYouTubePlayerDialog,
        fullscreen: "",
        persistent: "",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-0 ma-0" },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0 pa-0 ma-0",
              attrs: { dark: "", color: "#673AB7" },
            },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit(`close`)
                    },
                  },
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-0 ma-0 d-flex justify-center align-center" },
            [
              _c("iframe", {
                attrs: {
                  width: _vm.width,
                  height: _vm.height,
                  src: `https://www.youtube.com/embed/${_vm.youTubeVideoId}`,
                  title: "",
                  frameborder: "0",
                  allow:
                    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
                  allowfullscreen: "",
                },
              }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }