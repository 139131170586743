<template>
  <v-app id="version-log-page">
    <TopBar title="Version Log" />

    <v-main fluid tag="section" class="pt-0 content mainArea">
      <div class="mx-auto pb-8 px-3" style="max-width: 800px">
        <div v-for="n of releaseNotes" :key="n.version">
          <div class="my-3 pt-8 pb-1">
            Version {{ n.version }} - {{ n.date }}
          </div>
          <v-card class="pa-4">
            <v-card-text class="black--text">
              <div
                style="font-size: 16px"
                :class="n.notes && n.notes.length > 0 ? 'pb-2' : 'pb-0'"
              >
                {{ n.summary }}
              </div>
              <v-btn
                text
                class="pa-0 ma-0 text"
                v-if="getYouTubeVideoId(n.updateOverviewYouTubeUrl)"
                @click="
                  youTubeVideoUrl = n.updateOverviewYouTubeUrl;
                  showYouTubePlayerDialog = true;
                "
                color="#286054"
              >
                <v-icon class="mr-1">
                  {{ mdiYoutube }}
                </v-icon>
                Update Overview
              </v-btn>
              <div
                class="d-flex align-start py-4"
                v-for="note of n.notes"
                :key="note.description"
              >
                <div class="pr-5">
                  <img
                    v-if="note.type === 'Bug'"
                    height="36"
                    width="36"
                    src="@/assets/bug-check.svg"
                  />
                  <img
                    v-else
                    height="36"
                    width="36"
                    src="@/assets/star-circle-outline.svg"
                  />
                </div>

                <div>
                  <div class="d-flex align-center">
                    <h3 v-if="note.title" v-html="note.title"></h3>
                    <v-btn
                      text
                      class="py-0 my-0 text"
                      v-if="getYouTubeVideoId(note.featureOverviewYouTubeUrl)"
                      @click="
                        youTubeVideoUrl = note.featureOverviewYouTubeUrl;
                        showYouTubePlayerDialog = true;
                      "
                      color="#286054"
                    >
                      <v-icon class="mr-1">
                        {{ mdiYoutube }}
                      </v-icon>
                      Feature Overview
                    </v-btn>
                  </div>
                  <div
                    v-if="note.description"
                    v-html="note.description"
                    style="font-size: 16px"
                  ></div>
                  <div class="pt-6 pb-2" v-if="note.imageSrc">
                    <img
                      :src="note.imageSrc"
                      style="
                        border: 3px darkgrey solid;
                        border-radius: 6px;
                        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2),
                          0 3px 15px 0 rgba(0, 0, 0, 0.1);
                      "
                    />
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <YouTubePlayerDialog
        v-if="showYouTubePlayerDialog"
        :showYouTubePlayerDialog="showYouTubePlayerDialog"
        :youTubeVideoId="getYouTubeVideoId(youTubeVideoUrl)"
        @close="showYouTubePlayerDialog = false"
      />
    </v-main>
  </v-app>
</template>

<script>
import TopBar from "@/components/app/TopBar.vue";
import { mdiClose, mdiYoutube } from "@mdi/js";
import releaseNotes from "@/constants/releaseNotes";
import YouTubePlayerDialog from "@/components/versionLog/YouTubePlayerDialog.vue";

export default {
  components: {
    TopBar,
    YouTubePlayerDialog,
  },
  data() {
    return {
      mdiClose,
      releaseNotes,
      showYouTubePlayerDialog: false,
      youTubeVideoUrl: "",
      mdiYoutube,
    };
  },
  methods: {
    getYouTubeVideoId(youTubeUrl) {
      try {
        const url = new URL(youTubeUrl);
        return url.searchParams.get("v");
      } catch (error) {
        return "";
      }
    },
    onResize() {
      const [elHtml] = document.getElementsByTagName("html");
      if (this.$vuetify.breakpoint.xsOnly) {
        elHtml.style.overflowY = "auto";
        document.body.style.overflowY = "auto";
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "auto";
  },
};
</script>

<style scoped>
#version-log-page {
  background-color: #f1f2f1;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.content {
  flex: 1;
  overflow-y: auto;
}
</style>
