var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { attrs: { id: "version-log-page" } },
    [
      _c("TopBar", { attrs: { title: "Version Log" } }),
      _c(
        "v-main",
        {
          staticClass: "pt-0 content mainArea",
          attrs: { fluid: "", tag: "section" },
        },
        [
          _c(
            "div",
            {
              staticClass: "mx-auto pb-8 px-3",
              staticStyle: { "max-width": "800px" },
            },
            _vm._l(_vm.releaseNotes, function (n) {
              return _c(
                "div",
                { key: n.version },
                [
                  _c("div", { staticClass: "my-3 pt-8 pb-1" }, [
                    _vm._v(
                      " Version " +
                        _vm._s(n.version) +
                        " - " +
                        _vm._s(n.date) +
                        " "
                    ),
                  ]),
                  _c(
                    "v-card",
                    { staticClass: "pa-4" },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "black--text" },
                        [
                          _c(
                            "div",
                            {
                              class:
                                n.notes && n.notes.length > 0 ? "pb-2" : "pb-0",
                              staticStyle: { "font-size": "16px" },
                            },
                            [_vm._v(" " + _vm._s(n.summary) + " ")]
                          ),
                          _vm.getYouTubeVideoId(n.updateOverviewYouTubeUrl)
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "pa-0 ma-0 text",
                                  attrs: { text: "", color: "#286054" },
                                  on: {
                                    click: function ($event) {
                                      _vm.youTubeVideoUrl =
                                        n.updateOverviewYouTubeUrl
                                      _vm.showYouTubePlayerDialog = true
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { staticClass: "mr-1" }, [
                                    _vm._v(" " + _vm._s(_vm.mdiYoutube) + " "),
                                  ]),
                                  _vm._v(" Update Overview "),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._l(n.notes, function (note) {
                            return _c(
                              "div",
                              {
                                key: note.description,
                                staticClass: "d-flex align-start py-4",
                              },
                              [
                                _c("div", { staticClass: "pr-5" }, [
                                  note.type === "Bug"
                                    ? _c("img", {
                                        attrs: {
                                          height: "36",
                                          width: "36",
                                          src: require("@/assets/bug-check.svg"),
                                        },
                                      })
                                    : _c("img", {
                                        attrs: {
                                          height: "36",
                                          width: "36",
                                          src: require("@/assets/star-circle-outline.svg"),
                                        },
                                      }),
                                ]),
                                _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      note.title
                                        ? _c("h3", {
                                            domProps: {
                                              innerHTML: _vm._s(note.title),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.getYouTubeVideoId(
                                        note.featureOverviewYouTubeUrl
                                      )
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "py-0 my-0 text",
                                              attrs: {
                                                text: "",
                                                color: "#286054",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.youTubeVideoUrl =
                                                    note.featureOverviewYouTubeUrl
                                                  _vm.showYouTubePlayerDialog = true
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "mr-1" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.mdiYoutube) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" Feature Overview "),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  note.description
                                    ? _c("div", {
                                        staticStyle: { "font-size": "16px" },
                                        domProps: {
                                          innerHTML: _vm._s(note.description),
                                        },
                                      })
                                    : _vm._e(),
                                  note.imageSrc
                                    ? _c("div", { staticClass: "pt-6 pb-2" }, [
                                        _c("img", {
                                          staticStyle: {
                                            border: "3px darkgrey solid",
                                            "border-radius": "6px",
                                            "box-shadow":
                                              "0 2px 4px 0 rgba(0, 0, 0, 0.2),",
                                          },
                                          attrs: { src: note.imageSrc },
                                        }),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
          _vm.showYouTubePlayerDialog
            ? _c("YouTubePlayerDialog", {
                attrs: {
                  showYouTubePlayerDialog: _vm.showYouTubePlayerDialog,
                  youTubeVideoId: _vm.getYouTubeVideoId(_vm.youTubeVideoUrl),
                },
                on: {
                  close: function ($event) {
                    _vm.showYouTubePlayerDialog = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }